










// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// INTERFACES

@Component
/**
 * NOTE: Компонент-обёртка для частей формы заявки (отображение/создание).
 */
export default class SlotRequestFormPart extends Vue {
  @Prop({ required: true })
  readonly name!: string

  @Prop({ default: 0 })
  readonly number!: number

  @Prop({ default: false })
  readonly isCloseble!: boolean
}
