













































// Core
import { Component, PropSync, Vue } from 'vue-property-decorator'

// COMPONENTS
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'

// Types
import { ReportFieldsResource } from '@/store/types'

// Stores
import ReportsModule from '@/store/modules/reports'

@Component({
  components: {
    RequestFormPartWrapper,
  },
})
export default class ReportForm extends Vue {
  @PropSync('form', { default: null })
  private innerForm!: { name: string, fieldIds: number[][] } | null

  private get categories (): ReportFieldsResource[] {
    return ReportsModule.fields
  }

  private handleSelectAll (index: number) {
    if (this.innerForm) {
      if (this.innerForm.fieldIds[index].length !== this.categories[index].subcategories.length) {
        this.innerForm.fieldIds[index] = this.categories[index].subcategories.map(i => <number>i.value)
      } else {
        this.innerForm.fieldIds[index] = []
      }
    }

    this.$forceUpdate()
  }
}
